import React, { forwardRef, MutableRefObject } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import { GameTile } from './GameTile';
import { TBuildEventProperties } from './GameTileUtils';
import { TGameData, TGetFriendsResponse } from '../types/bedev1Types';
import '../../../../css/common/_gameCarousel.scss';

export type TGameCarouselProps = {
  gameData: TGameData[];
  friendData?: TGetFriendsResponse[];
  translate: WithTranslationsProps['translate'];
  buildEventProperties: TBuildEventProperties;
  tileRef?: MutableRefObject<HTMLDivElement | null>;
};

export const GameCarousel = forwardRef<HTMLDivElement, TGameCarouselProps>(
  (
    { gameData, buildEventProperties, translate, friendData, tileRef }: TGameCarouselProps,
    forwardedRef
  ) => {
    return (
      <div data-testid='game-carousel' ref={forwardedRef} className='game-carousel'>
        {gameData.map((data, positionId) => (
          <GameTile
            ref={tileRef}
            // eslint-disable-next-line react/no-array-index-key
            key={positionId}
            id={positionId}
            gameData={data}
            translate={translate}
            buildEventProperties={buildEventProperties}
            friendData={friendData}
          />
        ))}
      </div>
    );
  }
);
GameCarousel.displayName = 'GameCarousel';
export default GameCarousel;
