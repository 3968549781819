import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl } = EnvironmentUrls;

const url = {
  getExperimentationValues: (
    projectId: number,
    layerName: string,
    values: string[]
  ): { url: string; withCredentials: boolean } => ({
    url: `${apiGatewayUrl}/product-experimentation-platform/v1/projects/${projectId}/layers/${layerName}/values?parameters=${values.join(
      ','
    )}`,
    withCredentials: true
  })
};

const layerNames = {
  homePage: 'PlayerApp.HomePage.UX',
  serverTab: 'GameDetails.ServersTab',
  gameDetails: 'Website.GameDetails',
  searchPage: 'Website.SearchResultsPage'
};

const defaultValues = {
  homePage: {
    // NOTE (10/11/21, jpark):
    // Need to capitalize 'A' to be in line with mobile
    AvatarHomepageRecommendationsRowNum: 0,
    IsDiscoveryApiEnabled: true
    // Defaults to 100 to show the ads carousel as the last row
  },
  serverTab: {
    ShouldDisableJoinButtonForFullServers: false
  },
  gameDetails: {
    ShouldHidePrivateServersInAboutTab: false
  },
  searchPage: {
    ShouldShowFeaturedTileCreatorName: true,
    ShouldUseOmniSearchAPI: false,
    ShouldUseSentinelTile: false
  }
};

export default {
  url,
  defaultValues,
  layerNames
};
