import React, { useState, useEffect, useMemo, forwardRef, Ref } from 'react';
import { TGetFriendsResponse, TGetPlaceDetails } from '../types/bedev1Types';
import bedev1Services from '../services/bedev1Services';
import { getInGameFriends } from '../utils/parsingUtils';
import {
  TSharedGameTileProps,
  GameTileStats,
  GameTileBase,
  GameTileFriendsInGame,
  GameTileFriendActivityFooter
} from './GameTileUtils';
import '../../../../css/common/_gameTiles.scss';

export type TGameTileProps = TSharedGameTileProps & {
  friendData?: TGetFriendsResponse[];
};

export const GameTile = forwardRef<HTMLDivElement, TGameTileProps>(
  (
    { id, buildEventProperties, gameData, friendData = [] }: TGameTileProps,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    const [gameDetails, setGameDetails] = useState<TGetPlaceDetails | undefined>();
    const friendsInGame = useMemo(() => getInGameFriends(friendData, gameData.universeId), [
      friendData,
      gameData.universeId
    ]);

    useEffect(() => {
      const fetchGameDetails = async () => {
        try {
          const response = await bedev1Services.getPlaceDetails(gameData.placeId.toString());
          setGameDetails(response);
        } catch (e) {
          console.error(e);
        }
      };
      if (gameDetails === undefined && friendsInGame.length > 0) {
        // eslint-disable-next-line no-void
        void fetchGameDetails();
      }
    }, [friendsInGame, gameDetails]);

    const getGameTileContent = (): JSX.Element => {
      if (friendsInGame.length > 0 && gameDetails) {
        return <GameTileFriendsInGame friendData={friendsInGame} gameData={gameDetails} />;
      }
      if (gameData?.friendActivityTitle) {
        return <GameTileFriendActivityFooter footerText={gameData.friendActivityTitle} />;
      }
      return (
        <GameTileStats
          totalUpVotes={gameData.totalUpVotes}
          totalDownVotes={gameData.totalDownVotes}
          playerCount={gameData.playerCount}
        />
      );
    };

    return (
      <div ref={ref} className='grid-item-container game-card-container' data-testid='game-tile'>
        <GameTileBase id={id} buildEventProperties={buildEventProperties} gameData={gameData}>
          {getGameTileContent()}
        </GameTileBase>
      </div>
    );
  }
);
GameTile.displayName = 'GameTile';
export default GameTile;
