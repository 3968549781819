import { Presence } from 'Roblox';

// Common Types
export enum TGearCategoryType {
  Melee = 'Melee',
  Ranged = 'Ranged',
  Explosive = 'Explosive',
  PowerUps = 'PowerUps',
  Navigation = 'Navigation',
  Music = 'Music',
  Social = 'Social',
  Building = 'Building',
  PersonalTransport = 'PersonalTransport'
}

export enum TPageType {
  Home = 'Home',
  Games = 'Games'
}

export enum TContentType {
  Games = 'Games'
}

export enum TTreatmentType {
  Carousel = 'Carousel',
  GenreExplorerLabel = 'GenreExplorerLabel'
}

export enum TMetainfoValue {
  Invalid = 'Invalid',
  HasLootBoxes = 'HasLootBoxes',
  HasInGameTrading = 'HasInGameTrading',
  IsUsingLootBoxApi = 'IsUsingLootBoxApi',
  IsUsingInGameTradingApi = 'IsUsingInGameTradingApi',
  HasAllowedExternalLinkReferences = 'HasAllowedExternalLinkReferences',
  IsUsingAllowedExternalLinkReferencesApi = 'IsUsingAllowedExternalLinkReferencesApi'
}

export enum TUniverseAvatarType {
  MorphToR6 = 'MorphToR6',
  PlayerChoice = 'PlayerChoice',
  MorphToR15 = 'MorphToR15'
}

export type TBadge = {
  ImageId: number;
  PassName: string;
  PriceInRobux: number;
  UserOwns: boolean;
  ProductID: string;
  ProductAgentId: string;
  PlaceOwnerName: string;
};

export type TSort = {
  token: string;
  name: string;
  displayName: string;
  gameSetTargetId?: number;
  gameSetTypeId: number;
  contextCountryRegionId: number;
  tokenExpiryInSeconds: number;
};

export type TMetaData = {
  suggestedKeyword?: string;
  correctedKeyword?: string;
  filteredKeyword?: string;
  nextPageExclusiveStartId?: number;
  featuredSearchUniverseId?: number;
  hasMoreRows: boolean;
  emphasis: boolean;
  algorithm: string;
  algorithmQueryType: string;
  suggestionAlgorithm: string;
};

export type TGameData = {
  totalUpVotes: number;
  totalDownVotes: number;
  universeId: number;
  name: string;
  placeId: number;
  rootPlaceId?: number;
  playerCount: number;
  isSponsored?: boolean;
  nativeAdData?: string;
  isShowSponsoredLabel?: boolean;
  creatorName: string;
  creatorType: string;
  creatorId: number;
  creatorHasVerifiedBadge?: boolean;
  friendActivityTitle?: string;
};

export type TPresence = {
  gameId?: string;
  lastLocation: string;
  lastOnline: string;
  placeId?: number;
  placeUrl?: string;
  rootPlaceId?: number;
  universeId?: number;
  userId: number;
  userPresenceType: typeof Presence.PresenceTypes[keyof typeof Presence.PresenceTypes];
};

export type TGameCreator = {
  id: number;
  name: string;
  type: string;
};

export type TRatingInformation = {
  RatingValue: string;
  RatingDescriptors: string[];
  InteractiveElements: string[];
  MetainfoValues: TMetainfoValue[];
  ImageUrl: string;
  RatingValueDescription: string;
};

export type TRating = {
  RatingCountryCode?: string;
  RatingProvider: string;
  RatingInformation: TRatingInformation[];
  RatingProviderUrl: string;
};

// GetOmniRecommendations
export type TOmniRecommendation = {
  ContentType: TContentType.Games;
  ContentId: number;
};

export type TOmniRecommendationSort = {
  Topic: string;
  TopicId: number;
  TreatmentType: TTreatmentType;
  RecommendationList: TOmniRecommendation[];
};

export type TGetOmniRecommendationsMetadataResponse = {
  ContentMetadata: {
    [TContentType.Games]: Record<number, TGameData>;
  };
};

export type TGetOmniRecommendationsResponse = {
  Sorts: TOmniRecommendationSort[];
} & TGetOmniRecommendationsMetadataResponse;

// GetGameList
export type TGetGamesListResponse = {
  games: TGameData[];
  metaData: TMetaData;
};

// GetPlaceDetails
export type TGetPlaceDetails = {
  placeId: number;
  name: string;
  description: string;
  url: string;
  builder: string;
  builderId: number;
  isPlayable: boolean;
  reasonProhibited: string;
  universeId: number;
  universeRootPlaceId: number;
  price: number;
  imageToken: string;
};

// GetGameDetails
export type TGetGameDetails = {
  id: number;
  rootPlaceId?: number;
  name: string;
  description?: string;
  creator: TGameCreator;
  price?: number;
  allowedGearGenres: string[];
  allowedGearCategories: TGearCategoryType[];
  isGenreEnforced: true;
  isAllGenre: boolean;
  playing: number;
  visits: number;
  maxPlayers: number;
  favoritedCount: number;
  created: string;
  updated: string;
  copyingAllowed: boolean;
  studioAccessToApisAllowed: boolean;
  createVipServersAllowed: boolean;
  universeAvatarType: TUniverseAvatarType;
  genre: string;
  gameRating?: TRating;
  isFavoritedByUser: boolean;
};

// GetFriends
export type TGetFriendsResponse = {
  isOnline: boolean;
  isDeleted: boolean;
  description?: string;
  created: string;
  isBanned: boolean;
  id: number;
  name: string;
  displayName: string;
  presence?: TPresence;
  profileUrl?: string;
};

// GetPresenceUpdate
export type TPresenseUpdateEvent = CustomEvent<TPresence[] | undefined>;

// GetUniverseVoiceStatusResponse
export type TGetUniverseVoiceStatusResponse = {
  isUniverseEnabledForVoice: boolean;
};

// GetVoiceOptInStatusResponse
export type TGetVoiceOptInStatusResponse = {
  isUserOptIn: boolean;
};
