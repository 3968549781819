export const homePage = {
  maxTilesPerCarouselPage: 6
};

export const gameDetailsPage = {
  maxTilesPerCarouselPage: 6,
  voiceLabelIndex: 3
};

export const common = {
  maxTilesInGameImpressionsEvent: 25,
  numberOfInGameIcons: 3,
  numberOfInGameNames: 1,
  numberOfGameTilesPerLoad: 60,
  numberOfGamePassesPerLoad: 50,
  keyBoardEventCode: {
    enter: 'Enter'
  }
};

export const gameSearchPage = {
  // when 10% of pixels on sentinel tile are visible, load more data
  sentinelTileIntersectionThreshold: 0.1
};

export default {
  homePage,
  gameDetailsPage,
  common
};
