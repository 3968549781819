import { Link } from 'react-style-guide';
import React, { useEffect } from 'react';
import { eventStreamService } from 'core-roblox-utilities';
import { WithTranslationsProps } from 'react-utilities';
import browserUtils from '../../common/utils/browserUtils';
import { TOmniRecommendationSort } from '../../common/types/bedev1Types';
import { FeaturePlacesList } from '../../common/constants/translationConstants';
import eventStreamConstants, {
  PageContext,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import { usePerTabSession } from '../../common/hooks/usePerTabSession';
import '../../../../css/homePage/_genreTile.scss';

export type THomePageGenreTilesGamesApiProps = {
  sorts: TOmniRecommendationSort[];
  translate: WithTranslationsProps['translate'];
};
export const HomePageGenreTilesGamesApi = ({
  sorts,
  translate
}: THomePageGenreTilesGamesApiProps): JSX.Element => {
  const homePageSessionInfo = usePerTabSession(SessionInfoType.HomePageSessionInfo);

  useEffect(() => {
    const eventStreamParams = eventStreamConstants.genreExplorerRender({
      topics: sorts.map(sort => sort.TopicId),
      page: PageContext.HomePage,
      homePageSessionInfo
    });
    eventStreamService.sendEvent(...eventStreamParams);
  }, []);

  return (
    <React.Fragment>
      <h2 className='font-header-1'>{translate(FeaturePlacesList.LabelGenreExplorerName)}</h2>
      <div className='game-home-page-genre-explorer'>
        {sorts.map((sort, index) => (
          <Link
            key={sort.Topic}
            url={browserUtils.buildSortDetailUrl(sort.Topic, {
              position: index,
              sortId: sort.TopicId,
              page: PageContext.HomePage,
              treatmentType: sort.TreatmentType,
              homePageSessionInfo
            })}
            className='game-home-page-genre'>
            <span className='text-overflow font-bold'>{sort.Topic}</span>
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};

export default HomePageGenreTilesGamesApi;
