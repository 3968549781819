import React, { useEffect, useMemo, useState } from 'react';
import { AvatarShopHomepageRecommendations } from 'Roblox';
import { WithTranslationsProps } from 'react-utilities';
import HomePageCarouselDiscoveryApi from './HomePageCarouselDiscoveryApi';
import HomePageGenreTilesDiscoveryApi from './HomePageGenreTilesDiscoveryApi';
import useFriendsPresence from '../../common/hooks/useFriendsPresence';
import bedev2Services from '../../common/services/bedev2Services';
import ErrorStatus from '../../common/components/ErrorStatus';
import { homePage } from '../../common/constants/configConstants';
import { LoadingGameTile } from '../../common/components/LoadingGameTile';
import { CommonGameSorts } from '../../common/constants/translationConstants';
import { TGameData, TPageType } from '../../common/types/bedev1Types';
import {
  TCatalog,
  TContentType,
  TGetOmniRecommendationsResponse,
  TTreatmentType
} from '../../common/types/bedev2Types';

const { maxTilesPerCarouselPage } = homePage;

type THomePageDiscoveryApiProps = {
  translate: WithTranslationsProps['translate'];
  homePageSessionInfo: string | undefined;
};

export const HomePageDiscoveryApi = ({
  translate,
  homePageSessionInfo
}: THomePageDiscoveryApiProps): JSX.Element => {
  const friendsPresence = useFriendsPresence();
  const [recommendations, setRecommendations] = useState<
    TGetOmniRecommendationsResponse | undefined
  >(undefined);
  const [error, setError] = useState<boolean>(false);
  const genreExplorerSorts = useMemo(
    () =>
      recommendations?.sorts.filter(
        sort => sort.treatmentType === TTreatmentType.GenreExplorerLabel
      ),
    [recommendations]
  );

  const fetchRecommendations = async (): Promise<void> => {
    setError(false);
    try {
      const data = await bedev2Services.getOmniRecommendations(TPageType.Home, homePageSessionInfo);
      setRecommendations(data);
    } catch {
      setError(true);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchRecommendations();
  }, []);

  if (error) {
    return (
      <div className='game-home-page-container'>
        <h2>{translate(CommonGameSorts.LabelGames)}</h2>
        <ErrorStatus
          errorMessage={translate(CommonGameSorts.LabelApiError)}
          onRefresh={fetchRecommendations}
        />
      </div>
    );
  }

  if (recommendations === undefined) {
    return (
      <div className='game-home-page-container'>
        <div className='game-home-page-loading-title shimmer' />
        <div className='game-home-page-loading-carousel'>
          {Array.from({ length: maxTilesPerCarouselPage }, (_, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <LoadingGameTile key={id} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='game-home-page-container'>
      {genreExplorerSorts && genreExplorerSorts.length > 0 && (
        <HomePageGenreTilesDiscoveryApi translate={translate} sorts={genreExplorerSorts} />
      )}
      {recommendations.sorts
        .filter(
          sort =>
            sort.treatmentType === TTreatmentType.Carousel ||
            sort.treatmentType === TTreatmentType.AvatarCarousel
        )
        .map((sort, positionId) => {
          const carouselData = sort.recommendationList
            .map(({ contentType, contentId }: { contentType: TContentType; contentId: number }) => {
              const data = recommendations.contentMetadata[contentType][contentId];
              if (sort.treatmentType === TTreatmentType.AvatarCarousel && data) {
                const catalogData = data as TCatalog;
                catalogData.itemId = contentId;
                catalogData.itemType = contentType;
              } else if (sort.treatmentType === TTreatmentType.Carousel && data) {
                const gameData = data as TGameData;
                const nativeAdData =
                  sort.recommendationList[positionId]?.contentMetadata?.EncryptedAdTrackingData;
                gameData.isSponsored = nativeAdData?.length > 0;
                gameData.nativeAdData = nativeAdData;
              }

              return data;
            })
            .filter(recommendation => recommendation !== undefined)
            .slice(0, maxTilesPerCarouselPage);

          switch (sort.treatmentType) {
            case TTreatmentType.Carousel:
              return (
                <HomePageCarouselDiscoveryApi
                  key={sort.topic}
                  sort={sort}
                  gameData={carouselData as TGameData[]}
                  translate={translate}
                  positionId={positionId}
                  friendsPresence={friendsPresence}
                />
              );
            case TTreatmentType.AvatarCarousel:
              return (
                <AvatarShopHomepageRecommendations recommendedItems={carouselData as TCatalog[]} />
              );
            default:
              return null;
          }
        })}
    </div>
  );
};

export default HomePageDiscoveryApi;
