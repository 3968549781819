type TGameSearchPageContainerData = {
  keyword?: string;
  gameEmphasisVariation?: string;
};

// Container IDs
export const homePageContainerId = 'place-list';
export const homePageContainer = (): HTMLElement | null =>
  document.getElementById(homePageContainerId);
export const navigationContainerId = 'navigation-container';
export const navigationContainer = (): HTMLElement | null =>
  document.getElementById(navigationContainerId);
export const gameSearchPageContainerId = 'games-search-page';
export const gameSearchPageContainer = (): HTMLElement | null =>
  document.getElementById(gameSearchPageContainerId);
export const recommendedGamesContainerId = 'recommended-games-container';
export const recommendedGamesContainer = (): HTMLElement | null =>
  document.getElementById(recommendedGamesContainerId);
export const gameStoreContainerId = 'game-store-container';
export const gameStoreContainer = (): HTMLElement | null =>
  document.getElementById(gameStoreContainerId);

// Container Data Objects
export const isAutocompleteSuggestionsIXPTestEnabled = (): boolean =>
  parseInt(
    document.getElementById(navigationContainerId)?.dataset.numberOfAutocompleteSuggestions || '',
    10
  ) > 0;
export const gameSearchPageContainerData = (): TGameSearchPageContainerData | undefined =>
  document.getElementById(gameSearchPageContainerId)?.dataset;

// URL Params
export const queryParams = {
  keyword: 'keyword'
};

// URL Paths
export const url = {
  sortDetail: (sortName: string): string => `games#/sortName/v2/${sortName}`
};
