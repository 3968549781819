import React, { useRef } from 'react';
import { Link } from 'react-style-guide';
import { WithTranslationsProps } from 'react-utilities';
import { buildSortDetailUrl } from '../../common/utils/browserUtils';
import { FeaturePlacesList } from '../../common/constants/translationConstants';
import {
  EventStreamMetadata,
  PageContext,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import { TGameData, TGetFriendsResponse } from '../../common/types/bedev1Types';
import { TOmniRecommendationSort } from '../../common/types/bedev2Types';
import { GameCarousel } from '../../common/components/GameCarousel';
import { TBuildEventProperties } from '../../common/components/GameTileUtils';
import { usePerTabSession } from '../../common/hooks/usePerTabSession';
import useGameImpressionCarouselTracker from '../../common/hooks/useGameImpressionCarouselTracker';

type THomePageGameCarouselDiscoveryApiProps = {
  positionId: number;
  gameData: TGameData[];
  sort: TOmniRecommendationSort;
  friendsPresence: TGetFriendsResponse[];
  translate: WithTranslationsProps['translate'];
};

export const HomePageCarousel = ({
  translate,
  friendsPresence,
  gameData,
  sort,
  positionId
}: THomePageGameCarouselDiscoveryApiProps): JSX.Element => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const tileRef = useRef<HTMLDivElement>(null);
  const homePageSessionInfo = usePerTabSession(SessionInfoType.HomePageSessionInfo);
  const buildEventProperties: TBuildEventProperties = (data, id) => ({
    [EventStreamMetadata.PlaceId]: data.placeId,
    [EventStreamMetadata.UniverseId]: data.universeId,
    [EventStreamMetadata.IsAd]: data.isSponsored,
    [EventStreamMetadata.NativeAdData]: data.nativeAdData,
    [EventStreamMetadata.Position]: id,
    [EventStreamMetadata.SortPos]: positionId,
    [EventStreamMetadata.NumberOfLoadedTiles]: (gameData || []).length,
    [EventStreamMetadata.GameSetTypeId]: sort.topicId,
    [EventStreamMetadata.Page]: PageContext.HomePage,
    [SessionInfoType.HomePageSessionInfo]: homePageSessionInfo
  });

  const detailUrl = buildSortDetailUrl(sort.topic, {
    position: positionId,
    sortId: sort.topicId,
    page: PageContext.HomePage,
    treatmentType: sort.treatmentType,
    homePageSessionInfo
  });

  useGameImpressionCarouselTracker({ carouselRef, tileRef }, gameData, {
    sortPos: positionId,
    gameSetTypeId: sort.topicId,
    page: PageContext.HomePage,
    homePageSessionInfo
  });

  return (
    <React.Fragment>
      <div className='container-header'>
        <h2>
          <Link url={detailUrl}>{sort.topic}</Link>
        </h2>
        <Link url={detailUrl} className='btn-secondary-xs see-all-link-icon btn-more'>
          {translate(FeaturePlacesList.ActionSeeAll)}
        </Link>
      </div>
      <GameCarousel
        ref={carouselRef}
        tileRef={tileRef}
        gameData={gameData}
        friendData={friendsPresence}
        buildEventProperties={buildEventProperties}
        translate={translate}
      />
    </React.Fragment>
  );
};

export default HomePageCarousel;
