import { httpService } from 'core-utilities';
import { dataStores } from 'core-roblox-utilities';
import { authenticatedUser } from 'header-scripts';
import { AxiosResponse } from 'axios';
import bedev1Constants from '../constants/bedev1Constants';
import {
  TGetOmniRecommendationsMetadataResponse,
  TGetOmniRecommendationsResponse,
  TOmniRecommendation,
  TGetFriendsResponse,
  TGetPlaceDetails,
  TGetGameDetails,
  TPageType,
  TMetaData,
  TGameData,
  TGetUniverseVoiceStatusResponse,
  TGetVoiceOptInStatusResponse,
  TBadge,
  TSort
} from '../types/bedev1Types';

const { url, defaultCacheCriteria } = bedev1Constants;
const { gamesDataStore, userDataStoreV2 } = dataStores;
const { FriendsUserSortType } = dataStores.userDataStore;

export const getGameSorts = async (): Promise<{ sorts: TSort[] }> => {
  const {
    data: { sorts }
  } = await httpService.get<{ sorts: TSort[] }>(bedev1Constants.url.getGameSorts, {
    gameSortsContext: 'GamesDefaultSorts'
  });
  return { sorts };
};

export const getGameRecommendations = async (
  universeId: string,
  max: number
): Promise<{ games: TGameData[] }> => {
  const { data } = await httpService.get(bedev1Constants.url.getGameRecommendations(universeId), {
    maxRows: max
  });
  return data as Promise<{ games: TGameData[] }>;
};

export const getGamePasses = async (placeId: string, max: number): Promise<TBadge[]> => {
  const {
    data: { GamePasses }
  } = (await httpService.get(bedev1Constants.url.getGamePasses(placeId, max))) as {
    data: { GamePasses: TBadge[] };
  };
  return GamePasses;
};

export const getOmniRecommendations = async (
  pagetype: TPageType,
  sessionId?: string
): Promise<TGetOmniRecommendationsResponse> => {
  const { data } = await httpService.get(
    bedev1Constants.url.getOmniRecommendations(pagetype, sessionId)
  );
  return data as TGetOmniRecommendationsResponse;
};

export const getOmniRecommendationsMetadata = async (
  recommendationList: TOmniRecommendation[]
): Promise<TGetOmniRecommendationsMetadataResponse> => {
  const { data } = await httpService.post(bedev1Constants.url.getOmniRecommendationsMetadata, {
    Contents: recommendationList
  });
  return data as TGetOmniRecommendationsMetadataResponse;
};

export const getGameSortDetailList = async (
  sortToken: string,
  contextCountryRegionId: number,
  start: number,
  max: number,
  gameSetTargetId?: number,
  exclusiveStartId?: number
): Promise<{ metaData: TMetaData; games: TGameData[] }> => {
  const {
    data: { games = [], ...metaData }
  } = await httpService.get<{ games: TGameData[] } & TMetaData>(url.getGameList, {
    sortToken,
    gameSetTargetId,
    contextCountryRegionId,
    exclusiveStartId,
    startRows: start,
    maxRows: max
  });
  return { games, metaData };
};

export const getGameList = async (
  keyword: string,
  start: number,
  max: number
): Promise<{ metaData: TMetaData; games: TGameData[] }> => {
  const {
    data: { games = [], ...metaData }
  } = await httpService.get<{ games: TGameData[] } & TMetaData>(url.getGameList, {
    keyword,
    startRows: start,
    maxRows: max,
    isKeywordSuggestionEnabled: true
  });
  return { games, metaData };
};

export const getFriendsPresence = (): Promise<{ userData?: TGetFriendsResponse[] }> => {
  return userDataStoreV2.getFriends(
    {
      userId: authenticatedUser?.id,
      userSort: FriendsUserSortType.StatusFrequents,
      isGuest: false
    },
    defaultCacheCriteria
  ) as Promise<{ userData?: TGetFriendsResponse[] }>;
};

export const getPlaceDetails = async (placeId: string): Promise<TGetPlaceDetails> => {
  const { data = [] } = (await gamesDataStore.getPlaceDetails([placeId])) as AxiosResponse<
    TGetPlaceDetails[]
  >;
  return data[0];
};

export const getGameDetails = async (universeId: string): Promise<TGetGameDetails> => {
  const {
    data: { data }
  } = (await gamesDataStore.getGameDetails([universeId])) as AxiosResponse<{
    data: TGetGameDetails[];
  }>;
  return data[0];
};

export const getVoiceStatus = async (universeId: string): Promise<Record<string, boolean>> => {
  const universeVoiceStatusData = (
    await httpService.get(bedev1Constants.url.getUniverseVoiceStatus(universeId))
  ).data;
  const universeVoiceStatus = universeVoiceStatusData as TGetUniverseVoiceStatusResponse;
  const voiceOptInStatusData = (await httpService.get(bedev1Constants.url.getVoiceOptInStatus))
    .data;
  const voiceOptInStatus = voiceOptInStatusData as TGetVoiceOptInStatusResponse;
  const voiceStatus: Record<string, boolean> = {
    universeStatus: universeVoiceStatus.isUniverseEnabledForVoice,
    optInStatus: voiceOptInStatus.isUserOptIn
  };
  return voiceStatus;
};

export default {
  getFriendsPresence,
  getGameDetails,
  getGameList,
  getGamePasses,
  getGameRecommendations,
  getGameSortDetailList,
  getGameSorts,
  getOmniRecommendations,
  getOmniRecommendationsMetadata,
  getVoiceStatus,
  getPlaceDetails
};
