import React, { useState } from 'react';
import { Modal, Link } from 'react-style-guide';
import { WithTranslationsProps, withTranslations } from 'react-utilities';
import {
  Thumbnail2d,
  ThumbnailTypes,
  ThumbnailFormat,
  ThumbnailGameIconSize,
  ThumbnailAvatarHeadshotSize
} from 'roblox-thumbnails';
import parsingUtils from '../utils/parsingUtils';
import browserUtils from '../utils/browserUtils';
import { common } from '../constants/configConstants';
import { FeatureGamePage } from '../constants/translationConstants';
import { TGameDetailReferral } from '../constants/eventStreamConstants';
import { TGameData, TGetPlaceDetails, TGetFriendsResponse } from '../types/bedev1Types';
import PlayerInteractionModal from '../../../../js/react/gamePlayers/containers/PlayerInteractionModal';
import placesListTranslationConfig from '../../homePage/translation.config';
import '../../../../css/common/_gameTiles.scss';

const gameIconSize = 32;
const gameIconOverlap = 10;
const { keyBoardEventCode, numberOfInGameIcons, numberOfInGameNames } = common;
export type TBuildEventProperties = (gameData: TGameData, id: number) => TGameDetailReferral;
export type TSharedGameTileProps = {
  id: number;
  translate: WithTranslationsProps['translate'];
  buildEventProperties: TBuildEventProperties;
  gameData: TGameData;
};

export const GameTileBase = ({
  id,
  children,
  gameData,
  buildEventProperties
}: Omit<TSharedGameTileProps, 'translate'> & { children: JSX.Element }): JSX.Element => {
  return (
    <Link
      url={browserUtils.buildGameDetailUrl(
        gameData.placeId,
        gameData.name,
        buildEventProperties(gameData, id)
      )}
      className='game-card-link'
      id={gameData.universeId.toString()}>
      <Thumbnail2d
        type={ThumbnailTypes.gameIcon}
        size={ThumbnailGameIconSize.size150}
        targetId={gameData.universeId}
        containerClass='game-card-thumb-container'
        format={ThumbnailFormat.jpeg}
        altName={gameData.name}
      />
      <div className='game-card-name game-name-title' title={gameData.name}>
        {gameData.name}
      </div>
      {children}
    </Link>
  );
};

export const GameTileStats = ({
  totalDownVotes,
  totalUpVotes,
  playerCount
}: {
  totalUpVotes: number;
  totalDownVotes: number;
  playerCount: number;
}): JSX.Element => {
  const votes = parsingUtils.getVotePercentage(totalUpVotes, totalDownVotes);
  const players = parsingUtils.getAbbreviatedValue(playerCount);
  return (
    <div className='game-card-info' data-testid='game-tile-stats'>
      <span className='info-label icon-votes-gray' />
      {!votes ? (
        <span className='info-label no-vote' />
      ) : (
        <span className='info-label vote-percentage-label'>{votes}</span>
      )}
      <span className='info-label icon-playing-counts-gray' />
      <span className='info-label playing-counts-label'>{players}</span>
    </div>
  );
};

export const GameTileFriendActivityFooter = ({
  footerText
}: {
  footerText: string;
}): JSX.Element => {
  return (
    <div className='game-card-friend-activity' data-testid='game-tile-stats-friend-activity'>
      <div className='friend-activity-label'>{footerText}</div>
    </div>
  );
};

export const GameTileFriendsInGame = ({
  friendData,
  gameData,
  translate
}: {
  friendData: TGetFriendsResponse[];
  gameData: TGetPlaceDetails;
  translate?: WithTranslationsProps['translate'];
}): JSX.Element => {
  const [isModalShown, setModalShown] = useState(false);

  if (friendData.length === 0) {
    throw new Error('friendData should not be empty');
  }

  return (
    <div className='game-card-friend-info game-card-info' data-testid='game-tile-stats-friends'>
      <div
        className='info-avatar'
        style={{
          width: `${
            (friendData.slice(0, numberOfInGameIcons).length - 1) *
              (gameIconSize - gameIconOverlap) +
            gameIconSize
          }px`
        }}>
        {friendData.slice(0, numberOfInGameIcons).map(friend => (
          <div
            className='avatar-card'
            role='button'
            tabIndex={0}
            key={friend.displayName}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setModalShown(true);
            }}
            onKeyDown={e => {
              if (e.code === keyBoardEventCode.enter) {
                e.stopPropagation();
                e.preventDefault();
                setModalShown(true);
              }
            }}>
            <Thumbnail2d
              type={ThumbnailTypes.avatarHeadshot}
              size={ThumbnailAvatarHeadshotSize.size48}
              targetId={friend.id}
              containerClass='avatar avatar-headshot avatar-headshot-xs'
              imgClassName='avatar-card-image'
              format={ThumbnailFormat.png}
              altName={friend.displayName}
            />
          </div>
        ))}
      </div>
      {translate && (
        <span className='info-label text-overflow' data-testid='game-tile-stats-info-label'>
          {friendData.length > numberOfInGameNames
            ? translate(FeatureGamePage.LabelPlayingOnePlusUsersWithComma, {
                username: friendData[0].displayName,
                count: friendData.length - numberOfInGameNames
              })
            : translate(FeatureGamePage.LabelPlayingOneUser, {
                user: friendData[0].displayName
              })}
        </span>
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <ShimmedPlayerInteractionModal
        friendsDataInGame={friendData}
        game={gameData}
        show={isModalShown}
        onHide={e => {
          e.stopPropagation();
          e.preventDefault();
          setModalShown(false);
        }}
      />
    </div>
  );
};

GameTileFriendsInGame.defaultProps = {
  translate: undefined
};

type TShimmedPlayerInteractionModalComponentProps = {
  show: boolean;
  onHide: (e: Event) => void;
  friendsDataInGame: TGetFriendsResponse[];
  game: TGetPlaceDetails;
};

export const ShimmedPlayerInteractionModalComponent = ({
  show,
  onHide,
  friendsDataInGame,
  game,
  translate
}: TShimmedPlayerInteractionModalComponentProps & WithTranslationsProps): JSX.Element => (
  <Modal show={show} onHide={onHide} size='lg'>
    <PlayerInteractionModal
      friendsData={friendsDataInGame.map(friend => {
        return { ...friend, nameForDisplay: friend.displayName };
      })}
      friendsInGame={friendsDataInGame.map(friend => friend.id)}
      game={game}
      dismissModal={onHide}
      translate={translate}
    />
  </Modal>
);

export const ShimmedPlayerInteractionModal = withTranslations<TShimmedPlayerInteractionModalComponentProps>(
  ShimmedPlayerInteractionModalComponent,
  placesListTranslationConfig
);

export default {
  ShimmedPlayerInteractionModal,
  GameTileFriendsInGame,
  GameTileStats,
  GameTileBase
};
