import React, { useRef } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import {
  TGameData,
  TOmniRecommendationSort,
  TGetFriendsResponse
} from '../../common/types/bedev1Types';
import { GameCarousel } from '../../common/components/GameCarousel';
import { PageContext, SessionInfoType } from '../../common/constants/eventStreamConstants';
import { TBuildEventProperties } from '../../common/components/GameTileUtils';
import { usePerTabSession } from '../../common/hooks/usePerTabSession';
import useGameImpressionCarouselTracker from '../../common/hooks/useGameImpressionCarouselTracker';

type THomePageCarouselGamesApiProps = {
  positionId: number;
  gameData: TGameData[];
  sort: TOmniRecommendationSort;
  friendsPresence: TGetFriendsResponse[];
  translate: WithTranslationsProps['translate'];
};

export const HomePageCarouselGamesApi = ({
  translate,
  friendsPresence,
  gameData,
  sort,
  positionId
}: THomePageCarouselGamesApiProps): JSX.Element => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const tileRef = useRef<HTMLDivElement>(null);
  const homePageSessionInfo = usePerTabSession(SessionInfoType.HomePageSessionInfo);
  const buildEventProperties: TBuildEventProperties = (data, id) => ({
    placeId: data.placeId,
    universeId: data.universeId,
    isAd: data.isSponsored,
    nativeAdData: data.nativeAdData,
    position: id,
    sortPos: positionId,
    numberOfLoadedTiles: (gameData || []).length,
    gameSetTypeId: sort.TopicId,
    homePageSessionInfo,
    page: PageContext.HomePage
  });

  useGameImpressionCarouselTracker({ carouselRef, tileRef }, gameData, {
    sortPos: positionId,
    gameSetTypeId: sort.TopicId,
    page: PageContext.HomePage,
    homePageSessionInfo
  });

  return (
    <GameCarousel
      ref={carouselRef}
      tileRef={tileRef}
      gameData={gameData}
      friendData={friendsPresence}
      buildEventProperties={buildEventProperties}
      translate={translate}
    />
  );
};

export default HomePageCarouselGamesApi;
