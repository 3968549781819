import { Link } from 'react-style-guide';
import { AvatarShopHomepageRecommendations } from 'Roblox';
import React, { useEffect, useMemo, useState } from 'react';
import { WithTranslationsProps } from 'react-utilities';
import HomePageCarouselGamesApi from './HomePageCarouselGamesApi';
import HomePageGenreTilesGamesApi from './HomePageGenreTilesGamesApi';
import { buildSortDetailUrl } from '../../common/utils/browserUtils';
import { PageContext } from '../../common/constants/eventStreamConstants';
import useFriendsPresence from '../../common/hooks/useFriendsPresence';
import bedev1Services from '../../common/services/bedev1Services';
import ErrorStatus from '../../common/components/ErrorStatus';
import { homePage } from '../../common/constants/configConstants';
import { LoadingGameTile } from '../../common/components/LoadingGameTile';
import { CommonGameSorts, FeaturePlacesList } from '../../common/constants/translationConstants';
import {
  TGetOmniRecommendationsResponse,
  TPageType,
  TTreatmentType
} from '../../common/types/bedev1Types';

const { maxTilesPerCarouselPage } = homePage;

type THomePageGamesApiProps = {
  translate: WithTranslationsProps['translate'];
  avatarRecommendationIndex: number;
  homePageSessionInfo: string | undefined;
};

export const HomePageGamesApi = ({
  translate,
  homePageSessionInfo,
  avatarRecommendationIndex
}: THomePageGamesApiProps): JSX.Element => {
  const friendsPresence = useFriendsPresence();
  const [recommendations, setRecommendations] = useState<
    TGetOmniRecommendationsResponse | undefined
  >(undefined);
  const [error, setError] = useState<boolean>(false);
  const genreExplorerSorts = useMemo(
    () =>
      recommendations?.Sorts.filter(
        sort => sort.TreatmentType === TTreatmentType.GenreExplorerLabel
      ),
    [recommendations]
  );
  const fetchRecommendations = async (): Promise<void> => {
    setError(false);
    try {
      const data = await bedev1Services.getOmniRecommendations(TPageType.Home);
      setRecommendations(data);
    } catch {
      setError(true);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchRecommendations();
  }, []);

  if (error) {
    return (
      <div className='game-home-page-container'>
        <h2>{translate(CommonGameSorts.LabelGames)}</h2>
        <ErrorStatus
          errorMessage={translate(CommonGameSorts.LabelApiError)}
          onRefresh={fetchRecommendations}
        />
      </div>
    );
  }

  if (recommendations === undefined) {
    return (
      <div className='game-home-page-container'>
        <div className='game-home-page-loading-title shimmer' />
        <div className='game-home-page-loading-carousel'>
          {Array.from({ length: maxTilesPerCarouselPage }, (_, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <LoadingGameTile key={id} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div data-testid='game-home-page-container' className='game-home-page-container'>
      {genreExplorerSorts && genreExplorerSorts.length > 0 && (
        <HomePageGenreTilesGamesApi translate={translate} sorts={genreExplorerSorts} />
      )}
      {recommendations.Sorts.map((sort, positionId) => {
        switch (sort.TreatmentType) {
          case TTreatmentType.Carousel: {
            const gameData = sort.RecommendationList.map(
              ({ ContentType, ContentId }) =>
                recommendations.ContentMetadata[ContentType][ContentId]
            )
              .filter(recommendation => recommendation !== undefined)
              .slice(0, maxTilesPerCarouselPage);

            const detailUrl = buildSortDetailUrl(sort.Topic, {
              position: positionId,
              sortId: sort.TopicId,
              page: PageContext.HomePage,
              treatmentType: sort.TreatmentType,
              homePageSessionInfo
            });

            return (
              <React.Fragment key={sort.Topic}>
                {positionId + 1 === avatarRecommendationIndex && (
                  <AvatarShopHomepageRecommendations />
                )}
                <div className='container-header'>
                  <h2>
                    <Link url={detailUrl}>{sort.Topic}</Link>
                  </h2>
                  <Link url={detailUrl} className='btn-secondary-xs see-all-link-icon btn-more'>
                    {translate(FeaturePlacesList.ActionSeeAll)}
                  </Link>
                </div>
                <HomePageCarouselGamesApi
                  sort={sort}
                  gameData={gameData}
                  translate={translate}
                  positionId={positionId}
                  friendsPresence={friendsPresence}
                />
              </React.Fragment>
            );
          }
          default:
            return null;
        }
      })}
      {avatarRecommendationIndex > recommendations.Sorts.length && (
        <AvatarShopHomepageRecommendations />
      )}
    </div>
  );
};

export default HomePageGamesApi;
