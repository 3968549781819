import { Loading } from 'react-style-guide';
import React, { useEffect, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import HomePageGamesApi from './gamesApi/HomePageGamesApi';
import HomePageDiscoveryApi from './discoveryApi/HomePageDiscoveryApi';
import personalizationTranslationConfig from './translation.config';
import { SessionInfoType } from '../common/constants/eventStreamConstants';
import experimentConstants from '../common/constants/experimentConstants';
import usePerTabSession from '../common/hooks/usePerTabSession';
import bedev2Services from '../common/services/bedev2Services';

const { layerNames, defaultValues } = experimentConstants;

export const HomePageExperiment = ({ translate }: WithTranslationsProps): JSX.Element => {
  const homePageSessionInfo = usePerTabSession(SessionInfoType.HomePageSessionInfo, true);
  const [experimentValues, setExperimentValues] = useState<
    typeof defaultValues.homePage | undefined
  >(undefined);

  const fetchExperimentValues = async () => {
    const data = await bedev2Services.getExperimentationValues(
      layerNames.homePage,
      defaultValues.homePage
    );
    setExperimentValues(data);
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchExperimentValues();
  }, []);

  if (experimentValues?.IsDiscoveryApiEnabled === true) {
    return <HomePageDiscoveryApi homePageSessionInfo={homePageSessionInfo} translate={translate} />;
  }

  if (experimentValues?.IsDiscoveryApiEnabled === false) {
    return (
      <HomePageGamesApi
        avatarRecommendationIndex={experimentValues.AvatarHomepageRecommendationsRowNum}
        homePageSessionInfo={homePageSessionInfo}
        translate={translate}
      />
    );
  }

  return <Loading />;
};

export default withTranslations(HomePageExperiment, personalizationTranslationConfig);
