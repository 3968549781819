import { useEffect, useState } from 'react';
import { uuidService } from 'core-utilities';
import sessionStorageUtils from '../utils/sessionStorageUtils';
import { SessionInfoType } from '../constants/eventStreamConstants';

export const usePerTabSession = (
  sessionKey: SessionInfoType,
  isSetSessionEnabled = false
): string | undefined => {
  const [session, setSession] = useState<string | undefined>(
    sessionStorageUtils.getPerTabEventProperties([sessionKey])[sessionKey]
  );

  useEffect(() => {
    if (isSetSessionEnabled) {
      const uuid = uuidService.generateRandomUuid();
      sessionStorageUtils.setPerTabEventProperties({ [sessionKey]: uuid });
      setSession(uuid);
    }
  }, [sessionKey, isSetSessionEnabled]);

  return session;
};

export default usePerTabSession;
